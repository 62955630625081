import React, { useState } from 'react';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';

import { NoPermissions } from '../../Security/NoPermissions/NoPermissions';
import { ComplianceTable } from './ComplianceTable';

import { HR_DASHBOARD, ROUTERS } from '../../../constants';

import './compliance.scss'

interface ParamsI {
  tab: string
}

const COMPLIANCE_TABS: any = {
  myTeam: {
    key: 'myTeam',
    label: 'My Team Records',
    link: HR_DASHBOARD.HR_DASHBOARD_BASE_URL + ROUTERS.COMPLIANCE_SUBORDINATES_ROUTE
  },
  all: {
    key: 'all',
    label: 'All Records',
    link: HR_DASHBOARD.HR_DASHBOARD_BASE_URL + ROUTERS.COMPLIANCE_ALL_ROUTE
  }
}

export const Compliance: React.FC = () => {
  
  const params = useParams<ParamsI>();
  const { tab } = params;
  
  const [activeTab, setActiveTab] = useState<string>(tab === 'subordinates' ? COMPLIANCE_TABS.myTeam.key : COMPLIANCE_TABS.all.key);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [noPermissions, setNoPermissions] = useState<boolean>(false);

  return (
    <section className="edb-compliance-wrapper" id='wrapper'>
      {
        noPermissions
          ? <NoPermissions/>
          : (
            <>
              <div className="compliance-content">
                <ul className="compliance-navigation clearfloat">
                  {
                    Object.keys(COMPLIANCE_TABS).map((key: string, i: number) => {
                      return (
                        <li key={i}>
                          <NavLink
                            className={`nav-button${COMPLIANCE_TABS[key].key === activeTab ? ' nav-button--active' : ''}`}
                            onClick={() => {
                              setActiveTab(COMPLIANCE_TABS[key].key);
                            }}
                            to={ COMPLIANCE_TABS[key].link }>
                            { COMPLIANCE_TABS[key].label }
                          </NavLink>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="compliance-view">
                <Switch>
                  <Route
                    path={COMPLIANCE_TABS.myTeam.link}
                    render={() => (
                      <ComplianceTable
                        mode={ 'team' }
                        isLoading={ isLoading }
                        setIsLoading={ setIsLoading }
                        setNoPermissions={ setNoPermissions }
                      />
                    )}
                  />
                  <Route
                    path={COMPLIANCE_TABS.all.link}
                    render={() => (
                      <ComplianceTable
                        mode={ 'all' }
                        isLoading={ isLoading }
                        setIsLoading={ setIsLoading }
                        setNoPermissions={ setNoPermissions }
                      />
                    )}
                  />
                </Switch>
              </div>
            </>
          )
      }
    </section>
  );
}
