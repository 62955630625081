import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReportHeaderSorter } from '../ReportHeaderSorter/ReportHeaderSorter';

import { TABLE_CONFIG } from '../../../constants';

import './report-list.scss';

interface Props {
  isLoading: boolean,
  list: Array<any>,
  setList: Function,
  columnsHeader: Array<any>,
  columnsBody: Array<any>
}

export const ReportList: React.FC<Props> = (props: Props) => {
  const { isLoading, list, setList, columnsHeader, columnsBody } = { ...props };

  const defineContainerHeight = () => {
    const documentElementHeight: number = window.document.documentElement.clientHeight;
    const headerElement = document.getElementsByClassName('header-wrapper')[0] as HTMLElement;
    if (documentElementHeight && headerElement) {
      return `${documentElementHeight - headerElement.clientHeight}px`;
    } else {
      return '100vh';
    }
  }

  return (
    <div className="wr-list-wrapper" style={{height: defineContainerHeight()}}>
      <table>
        <thead>
        <tr>
          {
            columnsHeader.map((column: any, key: number) => (
              <th key={ key } style={ { width: column.width } } className="cell">
                <ReportHeaderSorter
                  label={ column.label }
                  fieldName={ column.name }
                  fieldType={ column.type }
                  list={ list }
                  setList={ setList }
                />
              </th>
            ))
          }
        </tr>
        </thead>
        {
          list.length > 0 && (
            <tbody>
            {
              list.map((workRecord: any, key: number) => {
                return (
                  <tr key={key}>
                    <td className="cell">
                      <NavLink to={`/staff/${workRecord.staffId}/work-records/${workRecord.id}/main`} target="_blank">
                        {workRecord.id}
                      </NavLink>
                    </td>
                    <td className="cell">
                      <NavLink to={`/staff/${workRecord.staffId}/work-records/${workRecord.id}/main`} target="_blank">
                        { workRecord.name }
                      </NavLink>
                    </td>
                    {
                      columnsBody.map((column: any, key: number) => (
                        <td key={key} className="cell">
                          { column.getValue(workRecord[column.name]) }
                        </td>
                      ))
                    }
                  </tr>
                );
              })
            }
            </tbody>
          )
        }
        {
          list.length === 0 && (
            <tbody>
            <tr>
              <td colSpan={ TABLE_CONFIG.reportTable.columns.length } className="cell">
                { !isLoading && <span>No results found.</span> }
                { isLoading && <span>Work records are being loaded.</span> }
              </td>
            </tr>
            </tbody>
          )
        }
      </table>
    </div>

  );
}
