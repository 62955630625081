import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { HCSnapshotLocationResponse } from '../../../interfaces/http/hrDashboard/hcSnapshot/HCSnapshotLocationResponse';
import { LocationForLocationResponse } from '../../../interfaces/http/hrDashboard/hcSnapshot/LocationResponse';
import { UnitReportItemResponse } from '../../../interfaces/http/hrDashboard/hcSnapshot/UnitReportItemResponse';
import {
  HCSnapshotLocationSettings,
  DEPARTMENT_TYPE,
  LOCATION_TYPE,
  ORG_STRUCTURE_TYPE,
  UNIT_TYPE
} from '../../../interfaces/entity/hrDashboard/HCSnapshotSettings';

import { HCSnapshotHeadcountData } from './HCSnapshotHeadcountData';

import './hc-snapshot-report.scss';

interface Props {
  data: HCSnapshotLocationResponse | null,
  settings: Array<HCSnapshotLocationSettings>,
  setSettings: Function,
  showDiff: boolean,
  setHeightReport: Function;
}

export const HCSnapshotLocationReport: React.FC<Props> = (props: Props) => {

  const { data, settings, setSettings } = { ...props };

  const changeDataSettings = (event: React.MouseEvent<HTMLParagraphElement>) => {
    let element = event.currentTarget,
      itemType = element.dataset.itemType,
      itemId = element.dataset.itemId ? parseInt(element.dataset.itemId) : null,
      itemParentId = element.dataset.itemParentId ? parseInt(element.dataset.itemParentId) : null,
      itemLocationId = element.dataset.itemLocationId ? parseInt(element.dataset.itemLocationId) : null;

    const copyOfSettings = Array.from(settings);

    copyOfSettings.forEach((item, index, array) => {
      let cond = item.type === itemType && item.id === itemId;
      if (itemType !== LOCATION_TYPE) {
        if (itemParentId) {
          cond = cond && item.parentId === itemParentId;
        }
        if (itemLocationId) {
          cond = cond && item.locationId === itemLocationId;
        }
      }
      if (cond) {
        array[index].expand = !item.expand;
      }
    });
    setSettings(copyOfSettings);
  }

  const getExpanded = (id: number,
                       type: string = LOCATION_TYPE,
                       parentId: number | null = null,
                       locationId: number | null = null) => {
    if (settings?.length) {
      let element = settings.find((element) => {
        let cond = element.id === id && element.type === type;
        if (type !== LOCATION_TYPE) {
          if (parentId) {
            cond = cond && element.parentId === parentId;
          }
          if (locationId) {
            cond = cond && element.locationId === locationId;
          }
        }
        return cond;
      });
      return element ? element.expand : false;
    }
    return false;
  }

  const getIcon = (id: string | number,
                   type: string = LOCATION_TYPE,
                   parentId: string | number | null = null,
                   locationId: string | number | null = null): IconProp => {
    if (typeof id === "string") {
      id = parseInt(id);
    }
    if (typeof parentId === "string") {
      parentId = parseInt(parentId);
    }
    if (typeof locationId === "string") {
      locationId = parseInt(locationId);
    }
    return getExpanded(id, type, parentId, locationId) ? ['fas', 'caret-down'] : ['fas', 'caret-right'];
  }
  
  

  return (
    <div className="headcount-report" style={{ height: props.setHeightReport() }}>
      <div id="headcount-report-scrollable-wrapper">
        <div className="headcount-report--header">
          <div className="row headcount-report--header-title">
            <div className="col">Total</div>
            <div className="col-4">
              <div className="row">
                <div className="col border border-bottom-0">
                  Global Headcount
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col border border-bottom-0">
                  Headcount FTE
                </div>
              </div>
            </div>
          </div>
          <div className="row headcount-report--header-data">
            <div className="col"/>
            <div className="col-4">
              <div className="row">
                <HCSnapshotHeadcountData
                  classValue="col border-left"
                  data={data ? data.head_count_billable : 0}
                  diff={data ? data.head_count_metric_diff.head_count_billable : 0}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col"
                  data={data ? data.head_count_non_billable : 0}
                  diff={data ? data.head_count_metric_diff.head_count_non_billable : 0}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col border-right"
                  data={data ? data.head_count_total : 0}
                  diff={data ? data.head_count_metric_diff.head_count_total : 0}
                  showDiff={props.showDiff}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <HCSnapshotHeadcountData
                  classValue="col border-left"
                  data={data ? data.billable_fte : 0}
                  diff={data ? data.head_count_metric_diff.billable_fte : 0}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col"
                  data={data ? data.non_billable_fte : 0}
                  diff={data ? data.head_count_metric_diff.non_billable_fte : 0}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col"
                  data={data ? data.fte_total : 0}
                  diff={data ? data.head_count_metric_diff.fte_total : 0}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col border-right"
                  data={data ? data.fte_ratio : 0}
                  diff={data ? data.head_count_metric_diff.fte_ratio : 0}
                  showDiff={props.showDiff}
                />
              </div>
            </div>
          </div>
          <div className="row headcount-report--header-subtitle">
            <div className="col">Location, Star Unit</div>
            <div className="col-4">
              <div className="row">
                <div className="col border-bottom border-left">Billable</div>
                <div className="col border-bottom">NB</div>
                <div className="col border-bottom border-right">Total</div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col border-bottom border-left" style={{ paddingBottom: '10px' }}>Billable</div>
                <div className="col border-bottom" style={{ paddingBottom: '10px' }}>NB</div>
                <div className="col border-bottom" style={{ paddingBottom: '10px' }}>Total</div>
                <div className="col border-bottom border-right" style={{ paddingBottom: '10px' }}>B/NB</div>
              </div>
            </div>
          </div>
        </div>
        <div className="headcount-report--body">
          {data?.locations && <div className="accordion">
            {(data.locations as Array<any>).map((location: LocationForLocationResponse) => (
              <div className="card" key={location.id}>
                <div className="card-header p-0" id={`location-${location.id}`}>
                  <div className="row headcount-report-row--location">
                    <div className="col">
                      <p className="m-0 py-0 position-relative collapse-toggle"
                         style={{ fontSize: '18px' }}
                         data-toggle="collapse"
                         data-target={`#collapse-location-${location.id}`}
                         data-item-id={location.id}
                         data-item-type="location"
                         aria-expanded="true"
                         aria-controls={`collapse-${location.id}`}
                         onClick={e => changeDataSettings(e)}>
                        {
                          location.unit_report_item.units.length
                            ? (
                              <FontAwesomeIcon
                                icon={getIcon(location.id, LOCATION_TYPE)}
                                className="position-absolute top-5 font-size--14"
                                data-item-id={location.id}
                                data-item-type={LOCATION_TYPE}
                              />
                            )
                            : <></>
                        }
                        <span className="location-name"
                              data-item-id={ location.id }
                              data-item-type="location">{ location.name }</span>
                      </p>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        <HCSnapshotHeadcountData
                          classValue="col"
                          data={location.head_count_billable}
                          diff={location.head_count_metric_diff.head_count_billable}
                          showDiff={props.showDiff}
                          location={location.name}
                          isBillable={true}
                          forDate={data?.for_date}
                        />
                        <HCSnapshotHeadcountData
                          classValue="col"
                          data={location.head_count_non_billable}
                          diff={location.head_count_metric_diff.head_count_non_billable}
                          showDiff={props.showDiff}
                          location={location.name}
                          isBillable={false}
                          forDate={data?.for_date}
                        />
                        <HCSnapshotHeadcountData
                          classValue="col"
                          data={location.head_count_total}
                          diff={location.head_count_metric_diff.head_count_total}
                          showDiff={props.showDiff}
                          location={location.name}
                          forDate={data?.for_date}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <HCSnapshotHeadcountData
                          classValue="col"
                          data={location.billable_fte}
                          diff={location.head_count_metric_diff.billable_fte}
                          showDiff={props.showDiff}
                          location={location.name}
                          isBillable={true}
                          forDate={data?.for_date}
                        />
                        <HCSnapshotHeadcountData
                          classValue="col"
                          data={location.non_billable_fte}
                          diff={location.head_count_metric_diff.non_billable_fte}
                          showDiff={props.showDiff}
                          location={location.name}
                          isBillable={false}
                          forDate={data?.for_date}
                        />
                        <HCSnapshotHeadcountData
                          classValue="col"
                          data={location.fte_total}
                          diff={location.head_count_metric_diff.fte_total}
                          showDiff={props.showDiff}
                          location={location.name}
                          forDate={data?.for_date}
                        />
                        <HCSnapshotHeadcountData
                          classValue="col"
                          data={location.fte_ratio}
                          diff={location.head_count_metric_diff.fte_ratio}
                          showDiff={props.showDiff}
                          location={location.name}
                          isNotClickable={true}
                          forDate={data?.for_date}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id={`collapse-location-${location.id}`}
                  className={`collapse ${getExpanded(parseInt(location.id), LOCATION_TYPE) === true ? 'show' : ''}`}
                  aria-labelledby={`location-${location.id}`}>
                  <div className="card-body p-0 font-size--14">
                    <div className="accordion">
                      {
                        (location.unit_report_item.units as Array<UnitReportItemResponse>).map((orgStructure: UnitReportItemResponse) => (
                          <div className="card border-0" key={orgStructure.id}>
                            <div className="card-header p-0" id={`location-${location.id}-org-structure-${orgStructure.id}`}>
                      
                              <div className="row border-bottom headcount-report-row--org-structure">
                                <div className="col">
                                  <p className="m-0 py-0 position-relative collapse-toggle"
                                     data-toggle="collapse"
                                     data-target={`#collapse-location-${location.id}-org-structure-${orgStructure.id}`}
                                     data-item-id={orgStructure.id}
                                     data-item-type={ORG_STRUCTURE_TYPE}
                                     data-item-location-id={location.id}
                                     aria-expanded="true"
                                     aria-controls={`collapse-${orgStructure.id}`}
                                     onClick={(e) => changeDataSettings(e)}>
                                    {
                                      (
                                        orgStructure.units.length
                                          ? (
                                            <FontAwesomeIcon
                                              icon={getIcon(orgStructure.id, ORG_STRUCTURE_TYPE, null, location.id)}
                                              className="position-absolute top-3 font-size--14"
                                              data-item-id={orgStructure.id}
                                              data-item-type={ORG_STRUCTURE_TYPE}
                                              data-item-location-id={location.id}
                                            />
                                          )
                                          : <></>
                                      )
                                    }
                                    <span className="location-name"
                                          data-item-id={orgStructure.id}
                                          data-item-type={ORG_STRUCTURE_TYPE}
                                          data-item-location-id={location.id}>{orgStructure.unit_name}</span>
                                  </p>
                                </div>
                                <div className="col-4">
                                  <div className="row">
                                    <HCSnapshotHeadcountData
                                      classValue="col"
                                      data={orgStructure.head_count_billable}
                                      diff={orgStructure.head_count_metric_diff.head_count_billable}
                                      showDiff={props.showDiff}
                                      location={location.name}
                                      type={orgStructure.type}
                                      orgStructure={orgStructure.unit_code_name}
                                      isBillable={true}
                                      forDate={data?.for_date}/>
                                    <HCSnapshotHeadcountData
                                      classValue="col"
                                      data={orgStructure.head_count_non_billable}
                                      diff={orgStructure.head_count_metric_diff.head_count_non_billable}
                                      showDiff={props.showDiff}
                                      location={location.name}
                                      type={orgStructure.type}
                                      orgStructure={orgStructure.unit_code_name}
                                      isBillable={false}
                                      forDate={data?.for_date}/>
                                    <HCSnapshotHeadcountData
                                      classValue="col"
                                      data={orgStructure.head_count_total}
                                      diff={orgStructure.head_count_metric_diff.head_count_total}
                                      showDiff={props.showDiff}
                                      location={location.name}
                                      type={orgStructure.type}
                                      orgStructure={orgStructure.unit_code_name}
                                      forDate={data?.for_date}/>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <HCSnapshotHeadcountData
                                      classValue="col"
                                      data={orgStructure.billable_fte}
                                      diff={orgStructure.head_count_metric_diff.billable_fte}
                                      showDiff={props.showDiff}
                                      location={location.name}
                                      type={orgStructure.type}
                                      orgStructure={orgStructure.unit_code_name}
                                      isBillable={true}
                                      forDate={data?.for_date}/>
                                    <HCSnapshotHeadcountData
                                      classValue="col"
                                      data={orgStructure.non_billable_fte}
                                      diff={orgStructure.head_count_metric_diff.non_billable_fte}
                                      showDiff={props.showDiff}
                                      location={location.name}
                                      type={orgStructure.type}
                                      orgStructure={orgStructure.unit_code_name}
                                      isBillable={false}
                                      forDate={data?.for_date}/>
                                    <HCSnapshotHeadcountData
                                      classValue="col"
                                      data={orgStructure.fte_total}
                                      diff={orgStructure.head_count_metric_diff.fte_total}
                                      showDiff={props.showDiff}
                                      location={location.name}
                                      type={orgStructure.type}
                                      orgStructure={orgStructure.unit_code_name}
                                      forDate={data?.for_date}/>
                                    <HCSnapshotHeadcountData
                                      classValue="col"
                                      data={orgStructure.fte_ratio}
                                      diff={orgStructure.head_count_metric_diff.fte_ratio}
                                      showDiff={props.showDiff}
                                      location={location.name}
                                      type={orgStructure.type}
                                      orgStructure={orgStructure.unit_code_name}
                                      isNotClickable={true}
                                      forDate={data?.for_date}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                    
                            <div
                              id={`collapse-location-${location.id}-org-structure-${orgStructure.id}`}
                              className={`collapse ${getExpanded(orgStructure.id, ORG_STRUCTURE_TYPE, null, parseInt(location.id)) === true ? 'show' : ''}`}
                              aria-labelledby={`location-${location.id}-org-structure-${orgStructure.id}`}>
                      
                              <div className="card-body p-0 font-size--14">
                        
                                <div className="accordion">
                          
                                  {
                                    ((orgStructure.units as Array<any>).map((department: UnitReportItemResponse) => (
                                      <div className="card border-0" key={department.id}>
                                        <div className="card-header p-0"
                                             id={`location-${location.id}-department-${department.id}`}>
                                  
                                          <div className="row border-bottom headcount-report-row--department">
                                            <div className="col">
                                              <p className="m-0 py-0 position-relative collapse-toggle"
                                                 data-toggle="collapse"
                                                 data-target={`#collapse-location-${location.id}-department-${department.id}`}
                                                 data-item-id={department.id}
                                                 data-item-type={DEPARTMENT_TYPE}
                                                 data-item-parent-id={orgStructure.id}
                                                 data-item-location-id={location.id}
                                                 aria-expanded="true"
                                                 aria-controls={`collapse-${department.id}`}
                                                 onClick={(e) => changeDataSettings(e)}>
                                                {
                                                  department.units.length
                                                    ? (
                                                      <FontAwesomeIcon
                                                        icon={getIcon(department.id, DEPARTMENT_TYPE, orgStructure.id, location.id)}
                                                        className="position-absolute top-3 font-size--14"
                                                        data-item-id={department.id}
                                                        data-item-type={DEPARTMENT_TYPE}
                                                        data-item-parent-id={orgStructure.id}
                                                        data-item-location-id={location.id}
                                                      />
                                                    )
                                                    : <></>
                                                }
                                                <span className="location-name"
                                                      data-item-id={department.id}
                                                      data-item-type={DEPARTMENT_TYPE}
                                                      data-item-parent-id={orgStructure.id}
                                                      data-item-location-id={location.id}>{department.unit_name}</span>
                                              </p>
                                            </div>
                                            <div className="col-4">
                                              <div className="row">
                                                <HCSnapshotHeadcountData
                                                  classValue="col"
                                                  data={department.head_count_billable}
                                                  diff={department.head_count_metric_diff.head_count_billable}
                                                  showDiff={props.showDiff}
                                                  location={location.name}
                                                  department={department.unit_code_name}
                                                  isBillable={true}
                                                  forDate={data?.for_date}/>
                                                <HCSnapshotHeadcountData
                                                  classValue="col"
                                                  data={department.head_count_non_billable}
                                                  diff={department.head_count_metric_diff.head_count_non_billable}
                                                  showDiff={props.showDiff}
                                                  location={location.name}
                                                  department={department.unit_code_name}
                                                  isBillable={false}
                                                  forDate={data?.for_date}/>
                                                <HCSnapshotHeadcountData
                                                  classValue="col"
                                                  data={department.head_count_total}
                                                  diff={department.head_count_metric_diff.head_count_total}
                                                  showDiff={props.showDiff}
                                                  location={location.name}
                                                  department={department.unit_code_name}
                                                  forDate={data?.for_date}/>
                                              </div>
                                            </div>
                                            <div className="col-6">
                                              <div className="row">
                                                <HCSnapshotHeadcountData
                                                  classValue="col"
                                                  data={department.billable_fte}
                                                  diff={department.head_count_metric_diff.billable_fte}
                                                  showDiff={props.showDiff}
                                                  location={location.name}
                                                  department={department.unit_code_name}
                                                  isBillable={true}
                                                  forDate={data?.for_date}/>
                                                <HCSnapshotHeadcountData
                                                  classValue="col"
                                                  data={department.non_billable_fte}
                                                  diff={department.head_count_metric_diff.non_billable_fte}
                                                  showDiff={props.showDiff}
                                                  location={location.name}
                                                  department={department.unit_code_name}
                                                  isBillable={false}
                                                  forDate={data?.for_date}/>
                                                <HCSnapshotHeadcountData
                                                  classValue="col"
                                                  data={department.fte_total}
                                                  diff={department.head_count_metric_diff.fte_total}
                                                  showDiff={props.showDiff}
                                                  location={location.name}
                                                  department={department.unit_code_name}
                                                  forDate={data?.for_date}/>
                                                <HCSnapshotHeadcountData
                                                  classValue="col"
                                                  data={department.fte_ratio}
                                                  diff={department.head_count_metric_diff.fte_ratio}
                                                  showDiff={props.showDiff}
                                                  location={location.name}
                                                  department={department.unit_code_name}
                                                  isNotClickable={true}
                                                  forDate={data?.for_date}/>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                
                                        <div
                                          id={`collapse-location-${location.id}-department-${department.id}`}
                                          className={`collapse ${getExpanded(department.id, DEPARTMENT_TYPE, orgStructure.id, parseInt(location.id)) === true ? 'show' : ''}`}
                                          aria-labelledby={`location-${location.id}-department-${department.id}`}>
                                  
                                          <div className="card-body p-0 font-size--14">
                                    
                                            <div className="accordion">
                                      
                                              {
                                                (department.units as Array<any>).map((unit: UnitReportItemResponse) => (
                                                  <div className="card border-0" key={unit.id}>
                                            
                                                    <div className="card-header p-0"
                                                         id={`location-${location.id}-unit-${unit.id}`}>
                                              
                                                      <div className="row border-bottom headcount-report-row--unit">
                                                        <div className="col">
                                                          <p className="m-0 py-0 position-relative collapse-toggle"
                                                             data-toggle="collapse"
                                                             data-target={`#collapse-location-${location.id}-unit-${unit.id}`}
                                                             data-item-id={unit.id}
                                                             data-item-type={UNIT_TYPE}
                                                             data-item-parent-id={department.id}
                                                             data-item-location-id={location.id}
                                                             aria-expanded="true"
                                                             aria-controls={`collapse-${unit.id}`}
                                                             onClick={(e) => changeDataSettings(e)}>
                                                            {
                                                              unit.units.length
                                                                ? (
                                                                  <FontAwesomeIcon
                                                                    icon={getIcon(unit.id, UNIT_TYPE, department.id, location.id)}
                                                                    className="position-absolute top-3 font-size--14"
                                                                    data-item-id={unit.id}
                                                                    data-item-type={UNIT_TYPE}
                                                                    data-item-parent-id={department.id}
                                                                    data-item-location-id={location.id}
                                                                  />
                                                                )
                                                                : <></>
                                                            }
                                                            <span className="location-name"
                                                                  data-item-id={unit.id}
                                                                  data-item-type={UNIT_TYPE}
                                                                  data-item-parent-id={department.id}
                                                                  data-item-location-id={location.id}>{unit.unit_name}</span>
                                                          </p>
                                                        </div>
                                                        <div className="col-4">
                                                          <div className="row">
                                                            <HCSnapshotHeadcountData
                                                              classValue="col"
                                                              data={unit.head_count_billable}
                                                              diff={unit.head_count_metric_diff.head_count_billable}
                                                              showDiff={props.showDiff}
                                                              location={location.name}
                                                              unit={unit.unit_code_name}
                                                              isBillable={true}
                                                              forDate={data?.for_date}/>
                                                            <HCSnapshotHeadcountData
                                                              classValue="col"
                                                              data={unit.head_count_non_billable}
                                                              diff={unit.head_count_metric_diff.head_count_non_billable}
                                                              showDiff={props.showDiff}
                                                              location={location.name}
                                                              unit={unit.unit_code_name}
                                                              isBillable={false}
                                                              forDate={data?.for_date}/>
                                                            <HCSnapshotHeadcountData
                                                              classValue="col"
                                                              data={unit.head_count_total}
                                                              diff={unit.head_count_metric_diff.head_count_total}
                                                              showDiff={props.showDiff}
                                                              location={location.name}
                                                              unit={unit.unit_code_name}
                                                              forDate={data?.for_date}/>
                                                          </div>
                                                        </div>
                                                        <div className="col-6">
                                                          <div className="row">
                                                            <HCSnapshotHeadcountData
                                                              classValue="col"
                                                              data={unit.billable_fte}
                                                              diff={unit.head_count_metric_diff.billable_fte}
                                                              showDiff={props.showDiff}
                                                              location={location.name}
                                                              unit={unit.unit_code_name}
                                                              isBillable={true}
                                                              forDate={data?.for_date}/>
                                                            <HCSnapshotHeadcountData
                                                              classValue="col"
                                                              data={unit.non_billable_fte}
                                                              diff={unit.head_count_metric_diff.non_billable_fte}
                                                              showDiff={props.showDiff}
                                                              location={location.name}
                                                              unit={unit.unit_code_name}
                                                              isBillable={false}
                                                              forDate={data?.for_date}/>
                                                            <HCSnapshotHeadcountData
                                                              classValue="col"
                                                              data={unit.fte_total}
                                                              diff={unit.head_count_metric_diff.fte_total}
                                                              showDiff={props.showDiff}
                                                              location={location.name}
                                                              unit={unit.unit_code_name}
                                                              forDate={data?.for_date}/>
                                                            <HCSnapshotHeadcountData
                                                              classValue="col"
                                                              data={unit.fte_ratio}
                                                              diff={unit.head_count_metric_diff.fte_ratio}
                                                              showDiff={props.showDiff}
                                                              location={location.name}
                                                              unit={unit.unit_code_name}
                                                              isNotClickable={true}
                                                              forDate={data?.for_date}/>
                                                          </div>
                                                        </div>
                                                      </div>
                                            
                                                    </div>
                                            
                                                    <div
                                                      id={`collapse-location-${location.id}-unit-${unit.id}`}
                                                      className={`${getExpanded(unit.id, UNIT_TYPE, department.id, parseInt(location.id)) === true ? 'show' : ''} collapse`}
                                                      aria-labelledby={`location-${location.id}-unit-${unit.id}`}>
                                              
                                                      <div className="card-body p-0 font-size--14">
                                                
                                                        {
                                                          (unit.units as Array<any>).map((team: UnitReportItemResponse) => (
                                                            <div key={team.id}>
                                                              <div className="row border-bottom headcount-report-row--team">
                                                                <div className="col">{team.unit_name}</div>
                                                                <div className="col-4">
                                                                  <div className="row">
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={team.head_count_billable}
                                                                      diff={team.head_count_metric_diff.head_count_billable}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      subunit={team.unit_code_name}
                                                                      isBillable={true}
                                                                      forDate={data?.for_date}/>
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={team.head_count_non_billable}
                                                                      diff={team.head_count_metric_diff.head_count_non_billable}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      subunit={team.unit_code_name}
                                                                      isBillable={false}
                                                                      forDate={data?.for_date}/>
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={team.head_count_total}
                                                                      diff={team.head_count_metric_diff.head_count_total}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      subunit={team.unit_code_name}
                                                                      forDate={data?.for_date}/>
                                                                  </div>
                                                                </div>
                                                                <div className="col-6">
                                                                  <div className="row">
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={team.billable_fte}
                                                                      diff={team.head_count_metric_diff.billable_fte}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      subunit={team.unit_code_name}
                                                                      isBillable={true}
                                                                      forDate={data?.for_date}/>
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={team.non_billable_fte}
                                                                      diff={team.head_count_metric_diff.non_billable_fte}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      subunit={team.unit_code_name}
                                                                      isBillable={false}
                                                                      forDate={data?.for_date}/>
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={team.fte_total}
                                                                      diff={team.head_count_metric_diff.fte_total}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      subunit={team.unit_code_name}
                                                                      forDate={data?.for_date}/>
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={team.fte_ratio}
                                                                      diff={team.head_count_metric_diff.fte_ratio}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      subunit={team.unit_code_name}
                                                                      isNotClickable={true}
                                                                      forDate={data?.for_date}/>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ))
                                                        }
                                              
                                                      </div>
                                            
                                                    </div>
                                          
                                                  </div>
                                                ))
                                              }
                                    
                                            </div>
                                  
                                          </div>
                                        </div>
                                      </div>
                                    )))
                                  }
                        
                                </div>
                      
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>}
        </div>
      </div>
    </div>
  );
}
