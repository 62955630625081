import { useEffect, useState } from 'react'

function getWindowDimensions() {
  const { clientWidth: width, clientHeight: height } = document.documentElement
  const headerElement = document.getElementsByClassName('header-wrapper')[0] as HTMLElement
  const switcherElement = document.getElementsByClassName('structure-nav')[0] as HTMLElement
  return {
    width,
    height: height - (headerElement?.clientHeight || 0) - (switcherElement?.clientHeight || 0)
  }
}

export default function useResizeHeightForChartHook() {
  const [dimensions, setDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return dimensions
}
