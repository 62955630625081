import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import client from '../../../services/http/FeedbackClient';

import { FeedbackItemResponse } from '../../../interfaces/http/feedback/FeedbackItemResponse';
import { FeedbackI } from '../../../interfaces/entity/Feedback';

import mapper from '../../../mappers/response/FeedbackMapper';

import { FeedbackItem } from '../FeedbackItem/FeedbackItem';
import { FeedbackListContext } from '../../WR/WRFeedback/WRFeedback';
import { Spinner } from '../../Spinner/Spinner';
import { NoPermissions } from '../../Security/NoPermissions/NoPermissions';

interface FeedbackListParams {
  wrId: string;
}

export const FeedbackList: React.FC = () => {
  const params = useParams<FeedbackListParams>();
  const cParams = {
    wrId: Number(params.wrId)
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState<Array<FeedbackI>>([]);
  const [noPermissions, setNoPermissions] = useState<boolean>(false);

  const context = useContext(FeedbackListContext);
  context.updateList = () => {
    setIsLoaded(false);
  }

  useEffect(() => {
    if (!isLoaded) {
      client.getFeedbacks(cParams.wrId)
        .then((response: any) => {
          const items: Array<FeedbackI> = [];
          if (response.data) {
            (response.data as Array<FeedbackItemResponse>).forEach((item: FeedbackItemResponse) => {
              items.push(mapper.creatFeedbackFromResponse(item));
            });
          }
          setItems(items);
          setIsLoaded(true);
        })
        .catch((reason: any) => {
          if (reason.status === 403) {
            setNoPermissions(true);
          } else {
            toastr.error('', 'This data is unavailable');
          }
          setIsLoaded(true);
        });
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return <Spinner/>;
  }

  return (
    <>
      {
        noPermissions
          ? <NoPermissions />
          : (
            <div className="education-list">
              {
                (items.length)
                  ? items.map((item: FeedbackI) =>
                    <Fragment key={item.id}>
                      <FeedbackItem feedback={ item } wrId={ cParams.wrId } />
                      <hr/>
                    </Fragment>
                  )
                  : <span className="education-list__empty">No feedbacks available yet</span>
              }
            </div>
          )
      }
    </>
  );
};
