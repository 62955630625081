import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';

import store from '../../../store/store';
import { GlobalState } from '../../../store/types';
import { setOverlayIsVisible } from '../../../store/overlay/actions';
import { loaderActions } from '../../../store/loader/actions';

import HRDashboardClient from '../../../services/http/HRDashboardClient';
import DictionaryClient from '../../../services/http/DictionaryClient';
import StaffClient from '../../../services/http/StaffClient';

import DocumentMapper from '../../../mappers/response/DocumentMapper';

import { DocumentItemResponse } from '../../../interfaces/http/document/DocumentItemResponse';
import { ComplianceItemI } from '../../../interfaces/DocumentItem';
import { ColumnT, ComplianceTableT, FilterOptionsT } from './ComplianceTableTypes';

import { FilterTrigger, FilterWrapper } from '../../Tables/Filters';
import { ComplianceTableColumnFilter } from './ComplianceTableColumnFilter';
import { ComplianceTableFilter } from './ComplianceTableFilter';
import { DownloadIcon } from '../../Icon/DownloadIcon';
import { PopupIndexedTrigger } from '../../Popup/PopupIndexedTrigger';
import { PopupIndexed } from '../../Popup/PopupIndexed';
import { Document } from '../../Document/Forms/Document';

import { COMPLIANCE_TABLE_COLUMNS } from './ComplianceTableColumns';

interface PropsI {
  mode: ComplianceTableT,
  isLoading: boolean,
  setIsLoading: Function,
  setNoPermissions: Function
}

export const ComplianceTable: React.FC<PropsI> = (props: PropsI) => {
  const columns: Array<ColumnT> = COMPLIANCE_TABLE_COLUMNS[props.mode];
  
  const initialState: FilterOptionsT = useMemo(() => {
    const initialState = {};
    columns.forEach(({ accessor }) => {
      Object.assign(initialState, { [accessor]: []});
    });
    return initialState as FilterOptionsT;
  }, [columns]);
  
  const [sorting, setSorting] = useState<any>({});
  const [filters, setFilters] = useState<FilterOptionsT>(initialState);
  const [filterOptions, setFilterOptions] = useState<FilterOptionsT>(initialState);
  
  const [data, setData] = useState<Array<ComplianceItemI>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(12);
  
  const myStaff = useSelector((state: GlobalState) => state.staff.myStaff);
  
  useEffect(() => {
    setCurrentPage(1);
    props.setIsLoading(true);
  }, [props.mode]);
  
  useEffect(() => {
    if (!props.isLoading) return;
    Promise
      .all([
        DictionaryClient.getComplianceDocumentAll(),
        DictionaryClient.getComplianceStatusAll(),
        StaffClient.getPpList()
      ])
      .then((response: Array<any>) => {
        let document = [];
        if (typeof response[0] !== 'undefined') {
          document = response[0].map((item: any) => {
            return { id: Number(item.id), name: item.name }
          });
        }
        let statusName = [];
        if (typeof response[1] !== 'undefined') {
          statusName = response[1].map((item: any, index: number) => {
            return { id: index, name: item };
          });
        }
        let peoplePartnerName = [];
        if (typeof response[2] !== 'undefined') {
          peoplePartnerName = response[2].map((item: any) => {
            return { id: Number(item.id), name: item.fullName }
          });
        }
        setFilterOptions({ ...filterOptions, document, statusName, peoplePartnerName});
      });
    
    store.dispatch(setOverlayIsVisible(true));
    store.dispatch(loaderActions.showLoader());
    
    const getDataByMode = props.mode === 'all'
      ? HRDashboardClient.getComplianceAll(currentPage, columns, filters, sorting)
      : HRDashboardClient.getComplianceSubordinates(currentPage, columns, filters, sorting);
    
    getDataByMode
      .then((data: any) => {
        if (typeof data !== 'undefined' && Array.isArray(data.items)) {
          const convertedData = data.items.map((item: DocumentItemResponse) => {
            return DocumentMapper.creatDocumentItemFromResponse(item);
          });
          setData(convertedData);
          setPageCount(data.pages);
        }
      })
      .catch((error: any) => {
        if (error.status === 403) {
          props.setNoPermissions(true);
          toastr.error('', 'No permissions to view this data');
        } else {
          toastr.error('', 'This data is unavailable');
        }
      })
      .finally(() => {
        props.setIsLoading(false);
        store.dispatch(setOverlayIsVisible(false));
        store.dispatch(loaderActions.hideLoader());
      });
    
  }, [props.isLoading]);
  
  useEffect(() => {
    props.setIsLoading(true);
  }, [filters, sorting]);
  
  const isExpired = (item: ComplianceItemI) => {
    let today = new Date();
    let dueDate = (item.dueDate) ? new Date(item.dueDate) : today;
    return dueDate < today && item.status === 0;
  };
  
  const canPreviousPage = () => {
    return currentPage > 1;
  }
  
  const canNextPage = () => {
    return currentPage < pageCount;
  }
  
  const previousPage = () => {
    const page = currentPage > 1 ? currentPage - 1 : currentPage;
    setCurrentPage(page);
    props.setIsLoading(true);
  }
  
  const nextPage = () => {
    const page = currentPage + 1;
    setCurrentPage(page);
    props.setIsLoading(true);
  }
  
  const exportCSV = () => {
    const getCsvByMode = props.mode === 'all'
      ? HRDashboardClient.getComplianceCSV(columns, filters, sorting)
      : HRDashboardClient.getComplianceSubordinatesCSV(columns, filters, sorting, myStaff.id);
    getCsvByMode
      .then((data: any) => {
        if (data) {
          const file = new Blob([data], { type: 'text/csv' });
          FileSaver.saveAs(file, 'complianceRecordsExport.csv');
          HRDashboardClient.http.unsetResponseType();
        }
      });
  }
  
  const handleSort = (column: ColumnT) => {
    let direction: string | null = 'ASC';
    if (sorting.hasOwnProperty(column.accessor)) {
      direction = sorting[column.accessor] === 'ASC' ? 'DESC' : null;
    }
    const sort = direction !== null ? { [column.accessor]: direction } : {};
    setSorting(sort);
  }
  
  const getValueByColumn = (row: ComplianceItemI, column: ColumnT) => {
    switch (column.accessor) {
      case 'fullName':
        return <NavLink to={`/staff/${ row.staffId }/work-records`}>{ row[column.accessor] }</NavLink>;
      case 'peoplePartnerName':
        return <NavLink to={`/staff/${ row.peoplePartnerId }/work-records`}>{ row[column.accessor] }</NavLink>;
      case 'document':
        return (
          <>
            <PopupIndexedTrigger popupName="document" popupIndex={ String(row.id) }>
              <span className="document-name-trigger edb-popover-trigger">{ row.document }</span>
            </PopupIndexedTrigger>
            <PopupIndexed
              popupName="document"
              popupIndex={String(row.id)}
              id="document"
              title="Accept Document/Policy"
              size="lg">
              <Document popupName="document" document={ row } />
            </PopupIndexed>
          </>
        );
      default:
        return <>{ row[column.accessor] }</>;
    }
  }
  
  return (
    <div className="compliance-table__wrapper">
      <div className="compliance-table__header">
        <div className="compliance-table__export">
          <span className="compliance-table__export-link" onClick={ exportCSV }>
            <DownloadIcon />
            <span>Export to CSV</span>
          </span>
        </div>
        <ComplianceTableFilter
          columns={ columns }
          filters={ filters }
          setFilters={ setFilters }
          initialState={ initialState }
        />
      </div>
      <div className="table-responsive compliance-table__body">
        <table className="table">
          <thead>
          <tr>
            {
              columns.map((column: ColumnT) => (
                <th key={ column.accessor }>
                  <div className="table-title">
                    {
                      column.sortable
                        ? (
                          <p className="table-title__with-sorting" onClick={ () => handleSort(column) }>
                            <span>{ column.header }</span>
                            <span className="table-sort">
                            {
                              (sorting && sorting.hasOwnProperty(column.accessor))
                                ? (
                                  (sorting[column.accessor] === 'ASC')
                                    ? <FA icon={['fas', 'long-arrow-alt-down']} />
                                    : <FA icon={['fas', 'long-arrow-alt-up']} />
                                )
                                : <FA icon={['fas', 'arrows-alt-v']} />
                            }
                          </span>
                          </p>
                        )
                        : <span>{ column.header }</span>
                    }
                    {
                      column.filterable
                        ? (
                          <div className="table-filter">
                            <FilterTrigger column={ column }>
                              <FA icon={ ['fas', 'filter'] } />
                            </FilterTrigger>
                            <ComplianceTableColumnFilter
                              key={ column.accessor }
                              column={ column }
                              options={ filterOptions[column.accessor] }
                              filters={ filters }
                              setFilters={ setFilters }
                            />
                          </div>
                        )
                        : <></>
                    }
                  </div>
                </th>
              ))
            }
          </tr>
          </thead>
          <tbody>
          {
            data.map((row: ComplianceItemI) => {
              return (
                <tr key={ row.id } className={ isExpired(row) ? 'expired' : '' }>
                  {
                    columns.map((column: ColumnT) => {
                      return (
                        <td key={ `${row.id}-${column.accessor}` }>
                          { getValueByColumn(row, column) }
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
          </tbody>
          <tfoot>
          <tr>
            <td colSpan={ columns.length - 2 }>
              <span className="pr-2">{ pageSize } records per page</span>
              <span className="pr-2">Page #{ currentPage } of { pageCount }</span>
            </td>
            <td colSpan={ 2 }>
              <button className="nav-previous" onClick={ previousPage } disabled={ !canPreviousPage() }>Prev</button>
              <button className="nav-next" onClick={ nextPage } disabled={ !canNextPage() }>Next</button>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}
