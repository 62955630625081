import React from 'react'
import moment from 'moment'

interface PropsI {
  cell: {
    value: string | undefined
  }
}

export const DateCell: React.FC<PropsI> = (props: PropsI) => {
  const { cell } = props
  const value = cell.value ? moment(cell.value).format('MMM DD, YYYY') : null
  
  if (value) return <>{ value }</>
  
  return <></>
}
