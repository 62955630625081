import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { StructureTypeSwitcher } from '../components/Structure/StructureTypeSwitcher/StructureTypeSwitcher';
import { UnitTree } from '../components/Structure/UnitTree/UnitTree';
import { LmTree } from '../components/Structure/LmTree/LmTree';

import './structure-page.scss';

export const StructurePage: React.FC = () => {
  return (
    <div className="content row no-gutters">
      <div className="structure col">
        <StructureTypeSwitcher/>
        <Switch>
          <Route path="/structure/unit-tree" component={ UnitTree } />
          <Route path="/structure/lm-tree/staff/:staffId/work-record/:wrId" component={ LmTree } />
          <Route path="/structure/lm-tree" component={ LmTree } />
        </Switch>
      </div>
    </div>
  );
}
