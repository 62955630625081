import React from 'react'
import { NavLink } from 'react-router-dom'
import useWindowDimensions from '../../../hooks/windowDimensionsHook'

interface Props {
  records: Array<any>,
  refTenure: any,
  trackScroll: Function
}

const emptyValue = '-'

export const TenureList: React.FC<Props> = (props: Props) => {
  const { records, refTenure, trackScroll } = { ...props }
  const { height } = useWindowDimensions()

  const getTenureDataHeight = (): number => {
    const filters = document.getElementsByClassName('tenure-filter-form')[0] as HTMLElement
    return height - (filters ? filters.clientHeight + 40 : 280)
  }

  return (
    <div
      className="tenure-list"
      ref={ refTenure }
      style={ { height: getTenureDataHeight() } }
      onScroll={(event) => {
        const scrollTop = Math.round(event.currentTarget.scrollTop);
        const scrollHeight = event.currentTarget.scrollHeight - getTenureDataHeight();
        trackScroll(scrollTop, scrollHeight);
      }}
    >
      <div className="tenure-data-table table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Employee</th>
              <th>Location</th>
              <th>Org Structure</th>
              <th>Department</th>
              <th>Sub-Department</th>
              <th>Team</th>
              <th>Line Manager</th>
              <th>Seniority</th>
              <th>Tenure</th>
            </tr>
          </thead>
          <tbody>
          {
            records.length > 0
              ? (
                records.map((record: any) => (
                  <tr key={ record.staff_id }>
                    <td>
                      <p className="">
                        <NavLink to={ `/staff/${record.staff_id}/work-records` }>
                          { record.full_name }
                        </NavLink>
                      </p>
                    </td>
                    <td>
                      <p className="">{ record.location || emptyValue }</p>
                    </td>
                    <td>
                      <p className="">{ record.org_structure || emptyValue }</p>
                    </td>
                    <td>
                      <p className="">{ record.department || emptyValue }</p>
                    </td>
                    <td>
                      <p className="">{ record.sub_department || emptyValue }</p>
                    </td>
                    <td>
                      <p className="">{ record.team || emptyValue }</p>
                    </td>
                    <td>
                      <p className="">{ record.line_manager || emptyValue }</p>
                    </td>
                    <td>
                      <p className="">{ record.seniority || emptyValue }</p>
                    </td>
                    <td>
                      <p className="">{ record.tenure }</p>
                    </td>
                  </tr>
                ))
              )
              : (
                <tr>
                  <td colSpan={ 9 }>
                    <p className="text-left font-weight-normal">No results found. Please clear the filters or try another search.</p>
                  </td>
                </tr>
              )
          }
          </tbody>
        </table>
      </div>
    </div>
  );
}
