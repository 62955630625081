import React, { useCallback, useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';

import store from '../../../store/store';
import { setOverlayIsVisible } from '../../../store/overlay/actions';
import { loaderActions } from '../../../store/loader/actions';

// Services
import HRDashboardClient from '../../../services/http/HRDashboardClient';
import HCDashboardHelper from '../../../services/data/HCDashboardHelper';

// Interfaces
import { HCSnapshotLocationResponse } from '../../../interfaces/http/hrDashboard/hcSnapshot/HCSnapshotLocationResponse';
import { HCSnapshotOrgStructureResponse } from '../../../interfaces/http/hrDashboard/hcSnapshot/HCSnapshotOrgStructureResponse';
import { HCSnapshotLocationSettings, HCSnapshotOrgStructureSettings } from '../../../interfaces/entity/hrDashboard/HCSnapshotSettings';

// Components
import { HCSnapshotHeader } from './HCSnapshotHeader';
import { HCSnapshotLocationReport } from './HCSnapshotLocationReport';
import { HCSnapshotOrgStructureReport } from './HCSnapshotOrgStructureReport';
import { NoPermissions } from '../../Security/NoPermissions/NoPermissions';

// Constants
import { HR_DASHBOARD_GROUP_BY_TYPE_KEYS } from '../../../constants';

// Styles
import './hc-snapshot.scss';

type GroupByType = typeof HR_DASHBOARD_GROUP_BY_TYPE_KEYS.LOCATION | typeof HR_DASHBOARD_GROUP_BY_TYPE_KEYS.STAR_UNIT;

export const HCSnapshot: React.FC = () => {
  const [asForDate, setAsForDate] = useState<Date>(new Date());
  const [compareDate, setCompareDate] = useState<Date | null>(null);
  const [groupBy, setGroupBy] = useState<GroupByType>(HR_DASHBOARD_GROUP_BY_TYPE_KEYS.LOCATION);
  const [noPermissions, setNoPermissions] = useState<boolean>(false);
  const [showDiff, setShowDiff] = useState<boolean>(false);

  const [data, setData] = useState<HCSnapshotOrgStructureResponse| HCSnapshotLocationResponse | null>(null);
  const [settings, setSettings] = useState<Array<HCSnapshotLocationSettings> | Array<HCSnapshotOrgStructureSettings> | null>(null);

  const getData = (groupBy: string, asForDate: Date, compareDate: Date | null) => {
    store.dispatch(setOverlayIsVisible(true));
    store.dispatch(loaderActions.showLoader());
    HRDashboardClient.getSnapshotHeadcountData(groupBy, asForDate, compareDate).then((data) => {
      if (groupBy === HR_DASHBOARD_GROUP_BY_TYPE_KEYS.LOCATION) {
        let convertedData = HCDashboardHelper.convertDataToHCSnapshotLocationResponse(data, compareDate);
        setData(convertedData);
        setSettings(HCDashboardHelper.initializeLocationSettings(convertedData));
      } else {
        let convertedData = HCDashboardHelper.convertDataToHCSnapshotOrgStructureResponse(data, compareDate)
        setData(convertedData);
        setSettings(HCDashboardHelper.initializeOrgStructureSettings(convertedData));
      }
    }).catch((error: any) => {
      if (error.status === 403) {
        setNoPermissions(true);
        toastr.error('', 'No permissions to view this data');
      } else {
        console.error(error);
      }
    }).finally(() => {
      store.dispatch(setOverlayIsVisible(false));
      store.dispatch(loaderActions.hideLoader());
    });
  }

  useEffect(() => {
    if (!asForDate && !compareDate) return;
    getData(groupBy, asForDate, compareDate);
  }, [groupBy, asForDate, compareDate]);

  useEffect(() => {
    setShowDiff(!!compareDate);
  }, [compareDate]);
  
  const setHeightReport = useCallback(() => {
    const headerElement = document.getElementsByClassName('header-wrapper')[0] as HTMLElement;
    const headerElementHeight = headerElement ? headerElement.clientHeight : 0;
    const headerCsvElement = document.getElementsByClassName('headcount-csv')[0] as HTMLElement;
    const headerCsvElementHeight = headerCsvElement ? headerCsvElement.clientHeight : 0;
    const height = window.document.documentElement.clientHeight - headerElementHeight - headerCsvElementHeight;
    return `${height}px`;
  }, []);

  return (
    <section className="h-100" style={{ background: "#e9f0f3" }}>
      {
        noPermissions
          ? <NoPermissions />
          : (
            <section className="bg-white">
              <HCSnapshotHeader
                asForDate={ asForDate }
                compareDate={ compareDate }
                groupBy={ groupBy }
                setAsForDate={ setAsForDate }
                setCompareDate={ setCompareDate }
                setGroupBy={ setGroupBy }
              />
      
              {
                (() => {
                  switch (groupBy) {
                    case HR_DASHBOARD_GROUP_BY_TYPE_KEYS.LOCATION:
                      return (
                        <HCSnapshotLocationReport
                          data={ data as HCSnapshotLocationResponse }
                          settings={ settings as Array<HCSnapshotLocationSettings> }
                          setSettings={ setSettings }
                          showDiff={ showDiff }
                          setHeightReport={ setHeightReport }
                        />
                      );
                    case HR_DASHBOARD_GROUP_BY_TYPE_KEYS.STAR_UNIT:
                      return (
                        <HCSnapshotOrgStructureReport
                          data={ data as HCSnapshotOrgStructureResponse }
                          settings={ settings as Array<HCSnapshotOrgStructureSettings> }
                          setSettings={ setSettings }
                          showDiff={ showDiff }
                          setHeightReport={ setHeightReport }
                        />
                      );
                  }
                })()
              }
            </section>
          )
      }
    </section>
  );
}
