import React from 'react'
import { Status } from '../../WR/Common/Status'

interface StatusCellProps {
  cell: {
    value: string;
  }
}

export const StatusCell: React.FC<StatusCellProps> = (props: StatusCellProps) => {
  const cell = props.cell
  const value:string = cell.value

  return (
    <Status value={ value } />
  )
}
