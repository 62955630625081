export const COMMON = {
  NOT_SPECIFIED: 'Not Specified',
  NOT_SET: 'Not set',
  GENERAL: {
    APP_NAME: 'Star'
  },
  LINKS: {
    WIKI: {
      CV_TERMS: "https://wiki.star.global/pages/viewpage.action?pageId=115048745"
    }
  },
  VERSION: '1.17.RC5'
};
