import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { HCSnapshot } from '../HCSnapshot/HCSnapshot'
import { Headcount } from '../Headcount/Headcount'
import { Tenure } from '../Tenure/Tenure'
import { Turnover } from '../Turnover/Turnover'
import { Leavers } from '../Leavers/Leavers'
import { Compliance } from '../Compliance/Compliance'

import { HR_DASHBOARD, ROUTERS } from '../../../constants'

import './hr-dashboard-wrapper.scss'

export const HRDashboardWrapper: React.FC = () => {
  return (
    <div className="edb-hr-dashboard-wrapper">
      <Switch>
        <Route path={`${HR_DASHBOARD.HR_DASHBOARD_BASE_URL}${ROUTERS.HC_SNAPSHOT_ROUTE}`} component={ HCSnapshot } />
        <Route path={`${HR_DASHBOARD.HR_DASHBOARD_BASE_URL}${ROUTERS.HEADCOUNT_ROUTE}`} component={ Headcount } />
        <Route path={`${HR_DASHBOARD.HR_DASHBOARD_BASE_URL}${ROUTERS.TENURE_ROUTE}`} component={ Tenure } />
        <Route path={`${HR_DASHBOARD.HR_DASHBOARD_BASE_URL}${ROUTERS.TURNOVER_ROUTE}`} component={ Turnover } />
        <Route path={`${HR_DASHBOARD.HR_DASHBOARD_BASE_URL}${ROUTERS.LEAVERS_ROUTE}`} component={ Leavers } />
        <Route path={`${HR_DASHBOARD.HR_DASHBOARD_BASE_URL}${ROUTERS.COMPLIANCE_ROUTE}/:tab`} component={ Compliance } />
      </Switch>
    </div>
  );
}
