import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';

// Store
import { GlobalState } from '../../store/types';
import { STAFF_STATE } from '../../store/reducers';

import Authorization from '../../services/authorization/Authorization';

// Components
import { QuickSearch } from './QuickSearch/QuickSearch';
import { Notification } from './Notification/Notification';
import { ElementWithPermission } from '../ElementWithPermission/ElementWithPermission';

import { HR_DASHBOARD, MAIL } from '../../constants';

import './header.scss';

export const Header: React.FC = () => {
  const productFeedbackMailHref = `mailto:${MAIL.productFeedback.mail}?Subject=${MAIL.productFeedback.subject}`;

  const { myStaff, staff } = useSelector((state: GlobalState) => state[STAFF_STATE]);
  const [avatar, setAvatar] = useState<string>('');

  useEffect(() => {
    if (myStaff) {
      setAvatar(myStaff.avatar);
    }
  }, [myStaff]);

  useEffect(() => {
    if (staff && myStaff && staff.id === myStaff.id) {
      setAvatar(staff.avatar);
    }
  }, [staff]);

  const logout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    Authorization.logout();
  };

  const checkActive = (match: any, location: any) => {
    if (!location) return false;
    const { pathname } = location;
    return ['/structure/unit-tree', '/structure/cm-tree'].includes(pathname) || pathname.toString().includes('/structure/lm-tree');
  }

  return (
    <header>
      <div className="header-wrapper">
        <div className="header-logo">
          <div className="logo" />
        </div>
        <div className="header-navigation">
          <ul className="nav">
            <ElementWithPermission element="orgChartTab">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/structure/unit-tree"
                  isActive={checkActive}
                  activeClassName="active">Org Chart
                </NavLink>
              </li>
            </ElementWithPermission>
            <ElementWithPermission element="allEmployeesTab">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/employee/list"
                  activeClassName="active">All Employees
                </NavLink>
              </li>
            </ElementWithPermission>
            <ElementWithPermission element="myTeamTab">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/my-team"
                  activeClassName="active">My Team
                </NavLink>
              </li>
            </ElementWithPermission>
            <ElementWithPermission element="hrDashboardTab">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/hr-dashboard/headcount-snapshot"
                  activeClassName="active"
                  isActive={(match, location) => {
                    return location.pathname.includes(HR_DASHBOARD.HR_DASHBOARD_BASE_URL);
                  }}>
                  HR Dashboard
                </NavLink>
              </li>
            </ElementWithPermission>
            <li className="nav-item">
              <a className="nav-link disabled" href="#">360° FEEDBACK</a>
            </li>
          </ul>
          <ElementWithPermission element="addEmployeeButton">
            <NavLink className="edb-btn edb-btn--primary"
                     to="/add-employee"
                     activeClassName="active"
                     data-testid="btnAddEmployee">
              Add employee
            </NavLink>
          </ElementWithPermission>
        </div>
        <div className="header-links">
          <div className="nav">
            <ElementWithPermission element="searchEmployee">
              <div className="nav-item" style={{ position: "relative" }}>
                <QuickSearch />
              </div>
            </ElementWithPermission>
            <div className="nav-item">
              <Notification />
            </div>
            <NavDropdown className="nav-item--avatar" title={ avatar ? <img src={avatar} /> : <></> } id="userMenu" data-testid="testUserMenu">
              <a className="dropdown-item" href="/my">My Profile</a>
              <ElementWithPermission element="administrationLink">
                <a className="dropdown-item" href="/admin/login" target="_blank">Administration</a>
              </ElementWithPermission>
              <a className="dropdown-item" href={ productFeedbackMailHref }>Product feedback</a>
              <a className="dropdown-item" href="" onClick={(e) => logout(e)}>Log out</a>
            </NavDropdown>
          </div>
        </div>
      </div>
    </header>
  )
}
